import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseOrderLineStatus } from '@core/interfaces/purchase-order-line-status.enum';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { BaseDataModule } from '@capturum/complete';

@Component({
  selector: 'app-purchase-order-status-widget',
  standalone: true,
  imports: [CommonModule, BaseDataModule],
  templateUrl: './purchase-order-status-widget.component.html',
  styleUrls: ['./purchase-order-status-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrderStatusWidgetComponent extends ListRendererWidgetBase {
  public iconMap = {
    [PurchaseOrderLineStatus.completed]: {
      icon: 'fas fa-check',
      class: 'success',
    },
    [PurchaseOrderLineStatus.notReceived]: {
      icon: 'fas fa-exclamation-triangle',
      class: 'danger',
    },
  };
}

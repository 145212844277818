import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from '@capturum/complete';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { environment } from '@environments/environment';
import { Confirmation, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [VersionCheckService],
})
export class AppComponent implements OnInit {
  public confirmation: Confirmation;

  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  public ngOnInit(): void {
    this.fetchVersionChanging();
    this.setTheme();

    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#0397cf',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#ff9500',
      [CSSVarNames.Error]: '#ff3b30',
      [CSSVarNames.Font]: '"Source Sans Pro", sans-serif',
    });
  }

  private fetchVersionChanging(): void {
    this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }
}

<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer [formKey]="formKey" [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="third" [label]="'button.cancel' | translate" (click)="cancel()"></cap-button>
    <cap-button
      styleClass="success"
      icon="fas fa-chevron-right"
      iconPos="right"
      [label]="'button.next' | translate"
      (click)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>

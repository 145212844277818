import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { User } from '@capturum/complete';
import { AppRoutes } from '@core/enums/routes.enum';
import { MenuItem as CompleteMenuItem } from 'primeng/api';
import { finalize, first } from 'rxjs/operators';

export interface MenuItem extends CompleteMenuItem {
  requiredPermission?: string;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  public menuItems: MenuItem[];
  public user: User;

  constructor(private readonly router: Router, private readonly authService: AuthService) {
    this.user = this.authService.getUser();
  }

  public ngOnInit(): void {
    this.menuItems = this.getMenuItems();
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  private getMenuItems(): MenuItem[] {
    return [
      {
        label: 'link.profile',
        icon: 'fas fa-user-circle',
        routerLink: `/${AppRoutes.user}/${AppRoutes.profile}`,
      },
      {
        label: 'config.manage.title',
        icon: 'fas fa-cog',
        routerLink: `/${AppRoutes.config}`,
        requiredPermission: 'module.manage.tenant', // @TODO: Find a permission only admins have
      },
      {
        label: 'link.logout',
        icon: 'fas fa-sign-in-alt',
        command: () => {
          this.authService
            .logout()
            .pipe(
              first(),
              finalize(() => {
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
              }),
            )
            .subscribe();
        },
      },
    ];
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HeaderConfig } from '@core/interfaces/header-config.interface';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  private headerConfig = new BehaviorSubject<HeaderConfig>({ title: null });

  public getHeaderConfig(): Observable<HeaderConfig> {
    return this.headerConfig.asObservable();
  }

  public setHeaderConfig(config: HeaderConfig): void {
    this.headerConfig.next(config);
  }

  public patchHeaderConfig(config: Partial<HeaderConfig>): void {
    this.headerConfig.next({
      ...this.headerConfig.getValue(),
      ...config,
    });
  }

  public resetHeaderConfig(): void {
    this.headerConfig.next({ title: '' });
  }
}

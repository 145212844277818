import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderConfig } from '@core/interfaces/header-config.interface';
import { ActivatedRoute } from '@angular/router';
import { HeaderConfigService } from '@core/services/header-config.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public title$: Observable<string>;
  public config$: Observable<HeaderConfig>;

  constructor(private headerConfigService: HeaderConfigService, public route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.config$ = this.headerConfigService.getHeaderConfig();

    this.title$ = this.headerConfigService.getHeaderConfig().pipe(
      map((config) => {
        return config.title;
      }),
    );
  }
}

import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Complete Frontend Boilerplate',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://stunning-viena-bptdmutpipsw.vapor-farm-b1.com/api',
  sentryUrl: null,
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'http://localhost:4200'
};

<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [modelId]="modelId"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="third" [label]="'button.cancel' | translate" (onClick)="cancel()"></cap-button>
    <cap-button styleClass="success" [label]="'button.submit' | translate" (onClick)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>

<div class="d-flex flex-row">
  <app-navigation-bar></app-navigation-bar>

  <div id="right">
    <div id="headerWrapper">
      <app-header></app-header>
    </div>

    <div id="wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

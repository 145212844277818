import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CapturumFormRendererModule, FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { first } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@core/components/dialog-layout/dialog-layout.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-good-receival-detail',
  standalone: true,
  imports: [CommonModule, DialogLayoutComponent, CapturumFormRendererModule, CapturumButtonModule, TranslateModule],
  templateUrl: './good-receival-detail.component.html',
  styleUrls: ['./good-receival-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoodReceivalDetailComponent {
  public formKey = 'form_goods_receival';

  constructor(
    private formSaver: FormSaverService,
    private dialogRef: DynamicDialogRef,
    private actionService: CapturumBuilderActionService,
  ) {}

  public submit(): void {
    this.formSaver
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.actionService.broadcastActionExecuted(
          { key: 'refresh', type: '', options: { refresh: true } },
          null,
          null,
        );

        this.dialogRef.close();
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}

import { Component, Renderer2, OnDestroy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DialogLayoutComponent implements OnDestroy {
  @Input() public preventRemoveDialogOpenClass: boolean;
  @Input() public showFooter = true;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'dialog-open');
  }

  public ngOnDestroy(): void {
    if (!this.preventRemoveDialogOpenClass) {
      this.renderer.removeClass(document.body, 'dialog-open');
    }
  }
}

<nav class="header" *ngIf="config$ | async as config">
  <div class="header-inner">
    <div class="header-inner-container">
      <div
        class="back-button center-content"
        [class.not-visible]="!config.backRoute"
        [routerLink]="[config.backRoute]"
        [relativeTo]="route">
        <div class="inner-back-button">
          <i class="fas fa-chevron-left"> </i>
          <span class="back-to-overview">{{ 'button.back-to-overview' | translate }}</span>
        </div>
      </div>

      <div class="title-container center-content">
        <span class="title">{{ config.title | translate }}</span>
      </div>

      <div class="menu-container">
        <app-user-menu></app-user-menu>
      </div>
    </div>
  </div>
</nav>

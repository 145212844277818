import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { environment } from '@environments/environment';
import { SidebarMenuItem } from '@capturum/ui/api/models/sidebar-menu-item.model';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationBarComponent extends DestroyBase implements OnInit {
  public appName = environment.appName;
  public navItems: SidebarMenuItem[];

  public constructor(public translateService: TranslateService) {
    super();
  }

  public ngOnInit(): void {
    this.navItems = this.getAllMenuItems();
  }

  private getAllMenuItems(): SidebarMenuItem[] {
    return [
      {
        label: this.translateService.instant('platvis.goods-receival.overview.title'),
        routerLink: AppRoutes.goodsReceival,
        icon: 'fas fa-truck',
      },
      {
        label: this.translateService.instant('platvis.articles.overview.title'),
        routerLink: AppRoutes.articles,
        icon: 'fas fa-calendar-day',
      },
      {
        label: this.translateService.instant('platvis.suppliers.overview.title'),
        routerLink: AppRoutes.suppliers,
        icon: 'fas fa-truck',
      },
      {
        hidden: true,
        label: this.translateService.instant('platvis.quality-info-lines.overview.title'),
        routerLink: AppRoutes.qualityInformationLines,
        icon: 'fas fa-solid fa-question',
      },
      {
        label: this.translateService.instant('platvis.locations.overview.title'),
        routerLink: AppRoutes.locations,
        icon: 'fas fa-map-marker',
      },
    ];
  }
}
